:root {
  --main-bg-color: rgba(0, 0, 0, 0.8);
  --accent-color: #fdc204;
  /* --text-color: #cccccc; */
  --text-color: #cfcfdc;
  /* --font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  --font-family: Tahoma, "Trebuchet MS", sans-serif;
  --section-padding: 20px;
  --section-margin: 20px 0;
  --card-max-width: 90%;
}

/*--------------------- global styles -------------------- */

/* section container */

.section-container {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0 60px 0;
  color: var(--text-color);
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

/* Mui global styles overrides */

.MuiCard-root,
.MuiPaper-root {
  background-color: rgba(0, 0, 0, 0);
  color: var(--text-color);
  box-shadow: none;
}

.title,
.subtitle {
  text-align: center;
  margin: 10px 0;
}

.title {
  font-size: 2em;
  font-weight: bold;
}

.subtitle {
  font-size: 1.2em;
}

.content {
  font-size: 1 em;
}

.section-wrapper {
  margin-bottom: 20px;
}

.card-container {
  width: var(--card-max-width);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 20px;
  }

  .content {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 18px;
  }

  .content {
    font-size: 14px;
  }
}
