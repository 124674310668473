@import "./App.css";

.big-blog-card-container {
  display: flex;
  padding: 60px 0 !important;
  width: 90%;
  margin: 60px auto;
}

.big-blog-card {
  width: 100%;
  margin: 20px;
  background-color: var(--main-bg-color) !important;
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.big-blog-card-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.blog-title {
  padding: 5px;
  padding-left: 30px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog-subtitle {
  padding: 5px;
  padding-left: 30px;
  font-size: 18px;
  color: var(--accent-color);
}

.blog-content {
  padding: 10px 30px 30px 30px;
  font-size: 16px;
  line-height: 1.6;
}

.social-icons-container {
  padding: 5px;
  padding-left: 30px;
  margin-bottom: 20px;
}

.blogs-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blogs-list-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 20px;
  text-align: center;
}

.blogs-list-item {
  background-color: var(--main-bg-color) !important;
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.blogs-list-item:hover {
  transform: translateY(-5px);
}

.blogs-list-item-content {
  display: flex;
  align-items: center;
}

.blogs-list-image {
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.blogs-list-text {
  display: flex;
  flex-direction: column;
}

.blogs-list-item-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.blogs-list-item-subtitle {
  font-size: 14px;
  color: var(--accent-color);
}
.blogs-list-item-date-time {
  margin-top: 5px;
  font-size: 10px;
  color: #d9d6d1;
}

.blogs-list-item-readmore {
  font-size: 14px;
  color: var(--accent-color);
  cursor: pointer;
}

@media (max-width: 768px) {
  .big-blog-card-container {
    flex-direction: column;
    padding: 20px;
  }

  .big-blog-card {
    margin-bottom: 20px;
  }

  .blogs-list {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .big-blog-card-container {
    margin: 0;
  }

  .blogs-list {
    width: 100% !important;
  }

  .blogs-list-item {
  }

  .blog-title {
    padding: 5px;
  }

  .blog-subtitle {
    padding: 5px;
  }

  .blog-content {
    padding: 0;
  }
}
