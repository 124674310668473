.notification-panel {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

.notification-panel.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

.notifications {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-card {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 20px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.notification-alert {
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toast-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
}

.toast-close {
  font-size: 20px;
  cursor: pointer;
  color: #535151;
}

.toast-content {
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.toast-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.toast-text {
  margin-left: 15px;
  flex: 1;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-notifications {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: var(--accent-color);
}

.notification-panel::-webkit-scrollbar {
  width: 8px;
}

.notification-panel::-webkit-scrollbar-track {
  background: var(--main-bg-color);
}

.notification-panel::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 10px;
  border: 2px solid var(--main-bg-color);
}

@media (max-width: 480px) {
  .notification-panel {
    right: 0;
  }
}

@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
