@import "./App.css";
.blog-page {
  margin-top: 70px !important;
  width: 90%;
  margin: 0 auto;
  padding: 60px 0 60px 0;
  color: var(--text-color);
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0);
}

.blogs-container {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.blog-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.arrow-icon {
  color: var(--text-color);
  font-size: 36px;
  cursor: pointer;
}

.blog-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.blog-card {
  /* width: 300px; 
  height: 250px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px !important; */
}

.view-all-button:hover {
  background: accent-color(45deg, #ff8e53 30%, #fe6b8b 90%);
}

.dots-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: var(--accent-color);
}

/* Popup styles */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--main-bg-color) !important;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 1001;
}

.popup-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* Hide arrow icons on smaller screens */
@media (max-width: 768px) {
  .arrow-icon {
    display: none;
  }
}
