@import "./App.css";

.tools-container {
  padding: 0 60px;
}

.tool-card {
  background-color: var(--main-bg-color) !important;
  color: var(--text-color) !important;
  padding: 20px;
  border-radius: 20px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tool-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.tool-icon {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.tool-title {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}

.tool-description {
  font-size: 14px;
  /* margin-bottom: 20px; */
  flex-grow: 1;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: auto;
}

@media (max-width: 768px) {
  .tools-card {
    padding: 40px;
  }

  .tool-card {
    padding: 20px;
  }

  .tool-title {
    font-size: 16px;
  }

  .tool-description {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .tools-card {
    padding: 20px;
  }

  .tool-card {
    padding: 10px;
  }

  .tool-title {
    font-size: 14px;
  }

  .tool-description {
    font-size: 12px;
  }
}
