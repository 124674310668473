@import "./App.css";

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

.quote,
.quoteby {
  background: var(--accent-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInOut 3s ease-in-out infinite;
}

.quote {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.quoteby {
  font-size: 1.5rem;
  text-align: right;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.animated-icon {
  width: 50px;
  height: 50px;
  position: absolute;
}

@keyframes fromTop {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

@keyframes fromRight {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100vw);
    opacity: 0;
  }
}

@keyframes fromBottom {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes fromLeft {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
  }
}

.fromTop {
  animation: fromTop 5s linear infinite;
}

.fromRight {
  animation: fromRight 5s linear infinite;
}

.fromBottom {
  animation: fromBottom 5s linear infinite;
}

.fromLeft {
  animation: fromLeft 5s linear infinite;
}

@media (max-width: 768px) {
  .loading-text {
    font-size: 1.5rem;
  }

  .animated-icon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .loading-text {
    font-size: 1.2rem;
  }

  .animated-icon {
    width: 30px;
    height: 30px;
  }
}
