html {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url("../assets/images/bg/bg-dark.webp");
  background-attachment: fixed;
  background-size: cover;
  color: var(--text-color) !important;
}

.no-scroll {
  overflow: hidden;
}

section {
  padding: var(--section-padding);
  margin: var(--section-margin);
}

.section::before {
  content: "";
  display: block;
  height: 60px;
  visibility: hidden;
}
