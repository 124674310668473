@import "./App.css";

@import url("https://fonts.cdnfonts.com/css/venite-adoremus");

.navbar {
  font-family: venite adoremus;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  transition: background 0.3s ease-in-out;
  height: 80px;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
}

.logo {
  padding: 10px 5px;
  color: var(--text-color);
  cursor: pointer;
  text-decoration: none;
  font-weight: 900;
  font-size: 30px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0 20px;
  transition: color 0.3s;
}

.logo:hover {
  background: var(--accent-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-menu {
  display: flex;
  gap: 40px;
  list-style: none;
  transition: transform 0.3s ease-in-out;
}

.nav-menu ul {
  display: flex;
  gap: 40px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu ul li a {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 22px;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
}

.nav-menu ul li a:hover {
  color: var(--accent-color);
  -webkit-background-clip: text;
  background-clip: text;
}

.nav-menu ul li a.active {
  color: var(--accent-color);
}

.social-icons {
  display: flex;
  gap: 20px;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: var(--text-color);
}

.notification-bell-container {
  position: relative;
}

.notification-icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s, transform 0.3s;
}

.notification-icon.active {
  transform: rotate(20deg);
}

.notification-icon:hover {
  color: var(--accent-color);
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--accent-color);
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .nav-menu {
    position: fixed;
    top: 80px;
    right: 0;
    height: calc(100vh - 80px);
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
  }

  .nav-menu.open {
    transform: translateX(0);
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 30px;
  }

  .nav-menu ul li a {
    font-size: 24px;
  }

  .hamburger {
    display: block;
    margin-right: 10px;
  }

  .notification-bell-container {
    display: block;
    margin-right: 10px;
  }

  .social-icons {
    display: none;
  }

  .nav-menu.open + .social-icons,
  .nav-menu.open + .social-icons.mobile-visible {
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
    gap: 10px;
  }

  .navbar.scrolled {
    background: rgba(0, 0, 0, 0.8);
  }

  .logo {
    margin: 0;
    font-size: 24px;
  }
}
