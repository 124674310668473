@import "./App.css";

.about-card {
  padding: 0 60px;
  text-align: left;
  height: auto;
  margin: auto;
}

.about-text {
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--text-color) !important;
}

.about-image {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .about-card {
    padding: 40px;
  }

  .about-text {
    font-size: 16px;
  }

  .about-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .about-card {
    padding: 20px;
  }

  .about-text {
    font-size: 14px;
  }
}
