@import "./App.css";

#coin {
  position: relative;
  width: 15rem;
  height: 15rem;
  margin: 2rem 0rem;
  transform-style: preserve-3d;
}

#coin div {
  backface-visibility: hidden;
  background-size: contain;
  position: absolute;
}

.heads {
  width: 240px;
  height: 240px;
  background-image: url("../assets/images/coinFLip/ffk.png");
}

.tails {
  width: 240px;
  height: 240px;
  background-image: url("../assets/images/coinFLip/idgaf.png");
  transform: rotateY(-180deg);
}

.continuous-rotate {
  animation: continuousRotate 3s linear infinite;
}

@keyframes continuousRotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
