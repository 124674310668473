@import "./App.css";

.landing-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  text-align: center;
  background-size: cover;
  padding: 20px;
}

.landing-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-coinflip-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.landing-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header-name {
  font-size: 72px;
  font-weight: bold;
  background: var(--accent-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.styled-header {
  font-size: 35px;
  margin: 0;
}

.sub-header {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.landing-buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.landing-coinflip-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (min-width: 768px) {
  .landing-section {
    flex-direction: column;
    text-align: center !important;
    padding: var(--section-padding);
    align-items: center;
  }

  .landing-content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .landing-content {
    align-items: center;
    text-align: left;
  }

  .landing-coinflip-wrapper {
    justify-content: center;
    padding-right: 50px;
  }

  .landing-content-wrapper {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .landing-section {
    padding: 20px;
  }

  .landing-content-wrapper {
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .styled-header {
    font-size: 32px;
  }

  .header-name {
    font-size: 48px;
    margin-bottom: 20px;
  }
  .landing-section {
    height: auto;
    padding: 10px;
  }

  .landing-content-wrapper {
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
  }

  .landing-whitepaper-btn,
  .landing-audit-btn {
    font-size: 16px !important;
  }
}

.glowing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --glow-0-percent: #5da9cc;
  --glow-50-percent: #165775;
  --glow-100-percent: #5da9cc;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px var(--glow-0-percent), 0 0 10px var(--glow-0-percent),
      0 0 20px var(--glow-0-percent), 0 0 30px var(--glow-0-percent),
      0 0 40px var(--glow-0-percent), 0 0 50px var(--glow-0-percent),
      0 0 75px var(--glow-0-percent);
  }
  50% {
    box-shadow: 0 0 10px var(--glow-50-percent), 0 0 20px var(--glow-50-percent),
      0 0 30px var(--glow-50-percent), 0 0 40px var(--glow-50-percent),
      0 0 50px var(--glow-50-percent), 0 0 75px var(--glow-50-percent),
      0 0 100px var(--glow-50-percent);
  }
  100% {
    box-shadow: 0 0 5px var(--glow-100-percent),
      0 0 10px var(--glow-100-percent), 0 0 20px var(--glow-100-percent),
      0 0 30px var(--glow-100-percent), 0 0 40px var(--glow-100-percent),
      0 0 50px var(--glow-100-percent), 0 0 75px var(--glow-100-percent);
  }
}

.glowing-circle {
  width: 280px;
  height: 20px;
  background: #4abcf0;
  border-radius: 50%;
  margin: auto;
  animation: glow 2s infinite;
}

/* .line-container {
  position: relative;
  height: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.line {
  width: 100px;
  height: 4px;
  background-color: #61dafb;
  position: absolute;
  top: 0;
  left: -100px;
  animation: moveLine 2s infinite linear;
}

@keyframes moveLine {
  0% {
    left: -100px;
  }
  100% {
    left: 100%;
  }
}
 */
