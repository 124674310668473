@import "./App.css";

.nfts-container {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.slider {
  width: 100%;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(350px * 14);
  animation: scroll 40s linear infinite;
}

.slide {
  width: 350px;
  height: 350px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-350px * 7));
  }
}
