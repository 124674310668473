@import "./App.css";

.faq-accordion {
  margin-top: 10px;
  border-radius: 8px;
}

.faq-answer {
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .faq-card {
    padding: 40px;
  }
}

@media screen and (max-width: 480px) {
  .faq-card {
    padding: 20px;
  }

  .faq-accordion {
    margin-top: 8px;
  }
}
