@import "./App.css";

.blog-card {
  width: 380px;
  height: auto;
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: var(--main-bg-color) !important;
  padding: 10px;
  margin-bottom: 20px; /* Add margin at the bottom */
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card-image {
  width: 100%;
  border-radius: 10px;
  height: auto;
  object-fit: cover;
}

.blog-card-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
}

.blog-card-label {
  font-size: 10px;
  color: var(--accent-color);
  margin-bottom: 5px;
}

.blog-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog-card-subtitle {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 10px;
}

.blog-card-description {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 10px;
}

.blog-card-meta {
  font-size: 11px;
  color: #d9d6d1;
  margin-top: auto;
}

.blog-cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Add gap between cards */
}

@media (max-width: 1280px) {
  .blog-card {
    width: 315px;
    height: auto;
  }
}
