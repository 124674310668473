@import "./App.css";

.tokenomics-card {
  /* padding: 0 120px; */
  text-align: center;
}

.tokenomics-image {
  width: 80%;
  height: auto;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .tokenomics-card {
    padding: 40px;
  }
}

@media (max-width: 480px) {
  .tokenomics-card {
    padding: 20px;
  }
  .tokenomics-image {
    width: 100%;
  }

  .tagline {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
