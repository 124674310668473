.toast-container {
  width: 400px;
  max-height: 400px;
  position: fixed;
  top: 10%;
  left: 84%;
  transform: translateX(-50%) translateY(200%);
  animation: noti 0.5s forwards ease-in-out;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 20px !important;
}

.toast-container.show {
  transform: translateX(-50%) translateY(0);
}

.toast-header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  padding: 8px;
}

.toast-close {
  font-size: 20px;
  cursor: pointer;
  color: #535151;
}

.toast-content {
  color: #000000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 6px;
}

.toast-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.toast-text {
  margin-left: 15px;
  flex: 1;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast-alert {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%) translateY(200%);
  animation: noti 0.5s forwards ease-in-out;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 20px !important;
  width: 380px;
}

.toast-alert.show {
  transform: translateX(-50%) translateY(0);
}

@keyframes noti {
  0% {
    transform: translateX(-50%) translateY(200%);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@media (max-width: 768px) {
  .toast-container {
    left: 50%;
    top: 80%;
  }
}

@media (max-width: 480px) {
  .toast-container {
    left: 50%;
    top: 75%;
  }
}
