@import "./App.css";

.nft-card {
  overflow: hidden;
  position: relative;
  width: 300px;
  height: 300px;
  animation: rotate 3s ease-in-out infinite;
}

.nft-card__image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nft-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nft-card__texture {
  animation: texture 3s ease-in-out infinite;
  background-image: accent-color(
    -80deg,
    hsla(0, 0%, 100%, 0.3) 25%,
    hsla(0, 0%, 100%, 0) 45%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}

.nft-card__info {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 0;
}

.nft-card__title {
  font-weight: bold;
  font-size: 1.2rem;
  /* color: #fff;  */
}

/* Animation */
@keyframes rotate {
  from,
  to {
    animation-timing-function: ease-in;
    box-shadow: 0 0 0 hsl(0, 0%, 80%), 0.1rem 0 0 hsl(0, 0%, 100%),
      -0.2rem 0 0.75rem 0 hsla(0, 0%, 0%, 0.3);
    transform: rotateY(-15deg);
  }
  25%,
  75% {
    animation-timing-function: ease-out;
    box-shadow: 0 0 0 hsl(0, 0%, 80%), 0 0 0 hsl(0, 0%, 100%),
      -0.25rem -0.05rem 1rem 0.15rem hsla(0, 0%, 0%, 0.3);
    transform: rotateY(0deg);
  }
  50% {
    animation-timing-function: ease-in;
    box-shadow: -0.1rem 0 0 hsl(0, 0%, 80%), 0 0 0 hsl(0, 0%, 100%),
      -0.3rem -0.1rem 1.5rem 0.3rem hsla(0, 0%, 0%, 0.3);
    transform: rotateY(15deg);
  }
}

@keyframes texture {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-50%, 0, 0);
  }
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 30%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}
