@import "./App.css";

.defame-container {
  padding: 60px 0 0 0 !important;
}

.defame-card {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 40px;
  padding: 0 30px;
}

.content {
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-color) !important;
}

.button-container {
  align-items: center;
  display: flex;
  justify-content: center !important;
  /* margin-top: 20px !important; */
}

@media (max-width: 768px) {
  .defame-card {
    padding: 40px;
  }

  .content {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .defame-card {
    padding: 20px;
  }

  .content {
    font-size: 14px;
  }
}
