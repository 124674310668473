@import "./App.css";

.MuiTimelineItem-root::before {
  flex: 0 !important;
}

.roadmap-container {
  text-align: center;
}

.roadmap-image {
  width: 80% !important;
  height: auto;
}

@media screen and (max-width: 768px) {
  .roadmap-header {
    font-size: 28px;
  }
  .roadmap-image {
    width: 100% !important;
    height: auto;
  }

  .MuiTimelineOppositeContent-root {
    display: none;
  }

  .MuiTimelineContent-root {
    text-align: left;
    padding-left: 20px;
  }
}
