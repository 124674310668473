@import "./App.css";

.story-section-card {
  padding: 0 60px;
  text-align: left;
  height: auto;
  margin: auto;
}

.story-section-image {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .story-section-card {
    padding: 40px;
  }

  .story-section-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .story-section-card {
    padding: 20px;
  }
}
