@import "./App.css";

.footer {
  background: var(--main-bg-color);
  color: var(--text-color);
  padding: 40px 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 60px;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* background: accent-color(45deg, rgba(0, 0, 0, 1), rgba(20, 20, 20, 0.8)); */
  z-index: 1;
}

.footer-container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  margin: 10px 14px;
  transition: transform 0.3s;
}

.footer-column:hover {
  transform: translateY(-10px);
}

.footer-column h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  background: var(--accent-color);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-column h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: var(--accent-color);
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin: 10px 0;
}

.footer-column ul li a {
  color: #bbb;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: var(--text-color);
}

.footer-column .social-icons {
  display: flex;
  margin-top: 20px;
}

.footer-column .social-icons a {
  color: #bbb;
  font-size: 1.5em;
  margin-right: 15px;
  transition: color 0.3s;
}

.footer-column .social-icons a:hover {
  color: rgb(135, 96, 203);
}

.footer-column .subscribe-form {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  background: #333;
  display: flex;
}

.footer-column .subscribe-form input[type="email"] {
  padding: 15px;
  width: 70%;
  border: 2px solid transparent; /* Initial transparent border */
  outline: none;
  border-radius: 25px 0 0 25px;
  background: #444;
  color: #bbb;
  transition: border 0.3s;
}

.footer-column .subscribe-form button {
  padding: 15px 20px;
  border: none;
  border-radius: 0 25px 25px 0;
  background: var(--accent-color);
  color: #fff;
  cursor: pointer;
  transition: background 0.3s;
}

.footer-column .subscribe-form button:hover {
  background: var(--accent-color);
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-column {
    min-width: 100%;
  }
}
