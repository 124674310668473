@import "./App.css";

.custom-button {
  background: #165775;
  border-radius: 30px;
  padding: 6px 16px;
  color: white;
  text-transform: none;
  transition: background 0.3s ease;
}
.custom-button:hover {
  background: #5da9cc;
}

.custom-button-lg {
  font-weight: bold;
  font-size: 16px;
  padding: 12px 30px;
  background-color: var(--accent-color);
}

.custom-button-lg:hover {
  color: var(--accent-color) !important;
  background-color: transparent !important;
  border: 3px solid var(--accent-color);
}
.custom-button-lg-2 {
  font-weight: bold;
  font-size: 16px;
  padding: 12px 30px;
  color: var(--accent-color);
  background-color: transparent !important;
  border: 3px solid var(--accent-color);
}

.custom-button-lg-2:hover {
  color: #fff !important;
  background-color: var(--accent-color) !important;
}

.custom-button.footer-button {
  padding: 10px 20px;
  font-size: 16px;
}

.custom-button.purchase-card-button {
  padding: 10px 20px;
  font-size: 16px;
}
